import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { ChevronDown, ChevronUp } from 'lucide-react'; // Retain existing icons
import { wrapupmain } from '../../config';
import { get_user_side_bar_access_details, get_org_admin } from 'store/access/api';
import { get_user_profile, fetch_sidebar_data, get_users_notification_count, users_notification, users_notification_seen } from "../../store/account/api";
import { WebSocketComponent } from "../index"

const SideBar = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const Getdata = localStorage.getItem("Access")
    const [sidebarItems, setSidebarItems] = useState([]);
    const [notificationList, setNotificationList] = useState([]);
    const state_access = useSelector(state => state.access);
    const state_account = useSelector(state => state.account);
    const [isOrgAdmin, setIsOrgAdmin] = useState(false);
    console.log(state_access.accesslist)
    const [expandedSections, setExpandedSections] = useState({});
    const email = localStorage.getItem("UserEmail")
    const Token = localStorage.getItem("Token")

    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token, (res) => {
            }));
        }

    }, []);

    const dispatch = useDispatch()
    const navigate = useNavigate();

    function sidebartoggle() {
        setShowSidebar(!showSidebar);
        var element = document.getElementById("main_section_toggle");
        element.classList.toggle("add-section");
        var element = document.getElementById("menu-btn");
        element.classList.toggle("manu-bar-fixed");
        // var element = document.getElementById("menu-btn");
        // element.classList.toggle("manu-bar-fixed");
    }

    useEffect(() => {
        if (state_account?.profile?.email) {
            fetchSidebar();
            fetchNotifications();
        }
    }, [state_account?.profile?.email]);

    const fetchNotifications = async () => {
        try {
            const data = { email: state_account.profile.email };

            // Dispatch action to fetch notifications count
            dispatch(get_users_notification_count(Token, data, (res) => {
                if (res.success) {
                    setNotificationList(res.data);
                } else {
                    console.error('Failed to fetch notifications:', res.error);
                }
            }));
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    const fetchSidebar = async () => {
        try {
            const email = state_account?.profile?.email;
            if (!email) return;

            // Check org admin status
            dispatch(get_org_admin({
                email,
                token: Token
            }, (res) => {
                if (res.success === true) {
                    const isAdmin = res.data.isOrgAdmin;
                    setIsOrgAdmin(isAdmin);

                    if (isAdmin) {
                        const sidebarPayload = {
                            role: 'admin',
                            email: state_account.profile.email
                        };

                        fetchAdminSidebar(sidebarPayload);
                    } else {
                        fetchUserSidebar(email);
                    }
                }
            }));
        } catch (error) {
            console.error('Error fetching sidebar:', error);
        }
    };

    const fetchAdminSidebar = (payload) => {
        dispatch(fetch_sidebar_data(Token, payload, (res) => {
            if (res.success) {
                const groupedData = groupSidebarItems(res.data.data);
                setSidebarItems(groupedData);
            } else {
                console.error('Failed to fetch sidebar data:', res.error);
            }
        }));
    };

    const fetchUserSidebar = (email) => {
        // Handle case where the user is not an admin
        const payload = {
            email: email,
            token: Token
        };

        // Dispatch action to fetch user access details
        dispatch(get_user_side_bar_access_details(payload, async (accessresponse) => {
            if (accessresponse.success) {
                const filteredData = accessresponse.data.filter(item => item.projectdetails[0].length === 0);
                const accessKeys = [filteredData.map(item => item.access.access)];

                // Prepare data for sidebar fetch
                const sidebarData = {
                    accessKey: accessKeys.join(','),
                    email: email,
                };

                // Dispatch action to fetch sidebar data for non-admin
                dispatch(fetch_sidebar_data(Token, sidebarData, (res) => {
                    if (res.success) {
                        const groupedData = groupSidebarItems(res.data.data);
                        setSidebarItems(groupedData);
                    } else {
                        console.error('Failed to fetch sidebar data:', res.error);
                    }
                }));
            }
        }));
    
    };

    const groupSidebarItems = (data) => {
        return data.reduce((acc, item) => {
            const parentKey = item.parent;
            if (!acc[parentKey]) {
                acc[parentKey] = [];
            }
            acc[parentKey].push(item);
            return acc;
        }, {});
    };






    const toggleSection = (section) => {
        setExpandedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    const renderSidebar = () => {
        const groupedData = {};
        const directItems = [];

        Object.keys(sidebarItems).forEach((key) => {
            if (key === '') {
                directItems.push(...sidebarItems[key]);
            } else {
                groupedData[key] = sidebarItems[key];
            }
        });

        return (
            <>
                {Object.keys(groupedData).map((key) => (
                    <div key={key} className="mb-2">
                        <button
                            onClick={() => toggleSection(key)}
                            style={{
                                width: '100%',
                                padding: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '16px',
                                justifyContent: 'space-between',
                                backgroundColor: 'transparent',
                                transition: 'background-color 0.3s',
                                color: 'white', // Set text color to white
                                border: 'none', // Remove the border
                                hover: {
                                    backgroundColor: '#f3f4f6', // Tailwind's gray-100 on hover
                                },
                                iconStyle: {
                                    color: 'white', // Set icon color to white
                                }
                            }}

                        >
                            <span
                                style={{
                                    fontWeight: '500',
                                    textTransform: 'capitalize',
                                    fontSize: '16px'
                                }}

                            >{key}</span>
                            {expandedSections[key] ? (
                                <ChevronUp className="w-4 h-4" />
                            ) : (
                                <ChevronDown className="w-4 h-4" />
                            )}
                        </button>
                        {expandedSections[key] && (
                            <div className="py-1">
                                {groupedData[key].map((item) => (
                                    <div key={item._id} style={{
                                        display: 'flex'
                                    }}>
                                        <a
                                            href={item.link}
                                            style={{
                                                padding: '0.5rem',
                                                paddingLeft: '3rem',
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5rem',
                                                marginBottom: '16px',
                                                hover: {
                                                    backgroundColor: '#f3f4f6'
                                                },
                                                fontSize: '0.875rem',
                                                color: 'white'
                                            }}
                                        >
                                            <img src={item.icon} alt={item.label} style={{
                                                width: '24px',
                                                height: '24px'
                                            }} />
                                            <span style={{
                                                fontSize: '16px'
                                            }}>{item.label}</span>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}

                {directItems.map((item) => (
                    <div key={item._id} className="flex">
                        <a
                            href={item.link}
                            style={{
                                width: '100%',
                                padding: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                marginBottom: '16px',
                                backgroundColor: 'transparent',
                                transition: 'background-color 0.3s',
                                color: 'white', // Set text color to white
                                border: 'none', // Remove the border
                                hover: {
                                    backgroundColor: '#f3f4f6', // Tailwind's gray-100 on hover
                                },
                                iconStyle: {
                                    color: 'white', // Set icon color to white
                                }
                            }}                        >
                            <img src={item.icon} alt={item.label} style={{
                                width: '24px',
                                height: '24px',

                            }} />
                            <span style={{
                                fontSize: '16px'
                            }}>{item.label}</span>
                        </a>
                    </div>
                ))}
            </>
        );
    };



    const handleLogout = () => {
        document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
        localStorage.clear();
        navigate(wrapupmain);
    };

    return (
        <>
            <WebSocketComponent />
            <div className={showSidebar ? 'showSidebar dashboard-nav' : 'dashboard-nav'}
            // style={{ display: showSidebar ? "none" : "block"}}
            >        <a href="/" style={{
                justifyContent: 'center',
                padding: '1rem',
                display: 'flex',
                alignItems: 'center',
            }}>
                    <img src="/images/wrapup-logo.svg" alt="Wrapup Logo" style={{
                        width: '100px',
                        justifyContent: 'center'
                    }} />
                </a>
                <nav className="dashboard-nav-list">
                    {renderSidebar()}
                    {/* Notification section */}
                    <div
                        style={{
                            width: '100%',
                            padding: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            marginBottom: '16px',
                            backgroundColor: 'transparent',
                            transition: 'background-color 0.3s',
                            color: 'white',
                            border: 'none',
                            hover: {
                                backgroundColor: '#f3f4f6',
                            },
                            iconStyle: {
                                color: 'white',
                            }
                        }}
                    >
                        <a
                            href="https://task.wrapup.live/notification"

                        >
                            <img
                                src="/images/sidebar/notification.svg"
                                alt="Notification"
                                style={{
                                    width: '24px', height: '24px',
                                    marginRight: '4px'
                                }}
                            />
                            <span style={{
                                fontSize: '16px'
                            }}>Notification</span>
                            {notificationList > 0 && (
                                <span
                                    style={{
                                        padding: '0.125rem 0.5rem',
                                        backgroundColor: '#6B7280',
                                        color: '#ffffff',
                                        borderRadius: '9999px',
                                        fontSize: '0.75rem',
                                        marginLeft: 'auto',
                                    }}
                                >
                                    {notificationList}
                                </span>
                            )}
                        </a>
                    </div>

                    {/* Logout section */}
                    <div className="flex hover:before:border mt-4">
                        <a
                            href="#"
                            onClick={handleLogout}
                            style={{
                                width: '100%',
                                padding: '1rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                marginBottom: '12px',
                                backgroundColor: 'transparent',
                                transition: 'background-color 0.3s',
                                color: 'white', // Set text color to white
                                border: 'none', // Remove the border
                                hover: {
                                    backgroundColor: '#f3f4f6', // Tailwind's gray-100 on hover
                                },
                                iconStyle: {
                                    color: 'white', // Set icon color to white
                                }
                            }}                          >
                            <img src="/images/sidebar/logout.svg" alt="Logout" style={{
                                width: '24px', height: '24px',
                                marginRight: '4px'
                            }} />
                            <span style={{
                                fontSize: '16px'
                            }}>Logout</span>
                        </a>
                    </div>
                </nav>


            </div >
            <div className="dashboard-toolbar" id="menu-btn">
                <a href="#" className="text-dark" onClick={sidebartoggle}>
                    <img src="/images/icons/menu-ico.svg" width={"16px"} />
                </a>
            </div>
        </>
    );
};
export { SideBar };




