import * as types from "./projectTypes";

let projectobj = {
  projectlist: [],
  addproject: false,
  projectdetails: false,
  searchproject: false,
  searchprojectlist: [],
  singleproject: {
    projectname: "",
    projectdescription: "",
    projectdetails: "",
    projectmanager: [],
    projectdeadline: "",
    projectcost: "",
    projectprice: "",
    projectcurrency: "",
    projectemployee: [],
    projectid: "",
    status: "",
    created_by: "",
    clientdetails: "",
    files: [],
    projectemail: []
  },
  singleprojectdetails: [],
  selectedprojectid: "",
  projectprice: "",
  projectcurrency: "",
  issuelist: [],
  issuedetails: "",
  issueid: '',
  selectedprojectdetails: "",
  projectwiseemaillist: [],
  accessibleProjectCount: 0,
  projectlistview: false,
  epiclist: [],
  selectedepic: "",
  loadingstatus: false,
  issuestatustype: "In Progress",
  organizationUsers: []
};



export default function projectReducer(state = projectobj, { type, payload }) {
  switch (type) {


    case types.ADD_SINGLE_PROJECT:
      projectobj.singleproject.projectemail = payload.projectemail
      projectobj.singleproject.projectname = payload.projectname;
      projectobj.singleproject.projectdescription = payload.projectdescription;
      projectobj.singleproject.projectdetails = payload.projectdetails;
      projectobj.singleproject.projectmanager = payload.projectmanager;
      projectobj.singleproject.projectdeadline = payload.projectdeadline;
      projectobj.singleproject.projectcost = payload.projectcost;
      projectobj.singleproject.projectprice = payload.projectprice;
      projectobj.singleproject.projectcurrency = payload.projectcurrency;
      projectobj.singleproject.projectemployee = payload.projectemployee
      projectobj.singleproject.projectid = payload.projectid
      projectobj.singleproject.status = payload.status
      projectobj.singleproject.created_by = payload.created_by
      projectobj.singleproject.clientdetails = payload.clientdetails
      projectobj.singleproject.files = payload.files

      return {
        ...state,
        loaded: true,
        loading: false,
        singleproject: projectobj.singleproject,
      };

    case types.SELECTAPROJECTFORACCESSSUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        selectedprojectdetails: payload,
      }
    case types.GETACCESSABLEPROJECTCOUNTSSUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        accessibleProjectCount: payload,
      }

    case types.GET_EPIC_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        epiclist: payload,
      }
    case types.GET_SELECTED_EPIC:
      return {
        ...state,
        loaded: true,
        loading: false,
        selectedepic: payload,
      }
    case types.PROJECTWISEGETUSERSSUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        projectwiseemaillist: payload,
      }

    case types.PROJECTLISTVIEW:
      return {
        ...state,
        loaded: true,
        loading: false,
        projectlistview: payload,
      }
    case types.GET_PROJECT_LIST_SUCCESS:

      return {
        ...state,
        loaded: true,
        loading: false,
        projectlist: payload,
      }

    case types.VIEW_PROJECT_ID_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        selectedprojectid: payload,
      }

    case types.ADD_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        addproject: payload,
      }

    case types.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        projectdetails: payload,
      }

    case types.GET_SINGLE_PROJECT_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        singleprojectdetails: payload,
      }

    case types.SEARCH_PROJECT_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        searchproject: payload,
      }
    case types.SEARCH_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        searchprojectlist: payload,
      }
    case types.PROJECTCOSTSUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        projectprice: payload,
      }
    case types.PROJECTCURRENCYSUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        projectcurrency: payload,
      }

    case types.JIRAISSUESFORAPROJECTSUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        issuelist: payload,
      }
    case types.JIRAISSUESDETAILSSUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        issuedetails: payload,
      }

    case types.VIEW_ISSUE_ID_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        issueid: payload,
      }

    case types.LOADING_STATUS_UPDATE:
      return {
        ...state,
        loaded: true,
        loading: false,
        loadingstatus: payload,
      }

    case types.ISSUE_STATUS_TYPE_UPDATE:
      return {
        ...state,
        loaded: true,
        loading: false,
        issuestatustype: payload,
      }

    case types.GET_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        organizationUsers: payload,
      }


    default:
      return state;
  }

}
