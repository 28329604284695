import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_user_side_bar_access_details, get_org_admin } from "../../store/access/api"
import {get_user_profile} from "../../store/account/api"
import { useNavigate } from 'react-router-dom';

const UserNavItems = () => {
    const dispatch = useDispatch()
    const state_account = useSelector(state => state.account);
    const AccessKeys = useSelector(state => state.access.usersidebaraccess);
    const Token = localStorage.getItem("Token")
    const navigate = useNavigate();

    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                if (res.success == true) {
                    const payload = {
                        email: state_account.profile.email,
                        token: Token
                    }
                    dispatch(get_user_side_bar_access_details(payload, (res) => {
                    }))
                    dispatch(get_org_admin(payload, (res)=>{
                    }))

                }
            })))
        }else{
            navigate('/')
        }


    }, []);
    return (
        <>
            <div className="flex hover:before:border">
                {AccessKeys.includes("projects") && (
                    <a href="/projects" className="dashboard-nav-item">
                        <img src="/images/sidebar/project.svg" alt="" /> Projects
                    </a>
                )}
            </div>
            <div className="flex before:border">
                {AccessKeys.includes("tickets") && (
                    <a href="https://ticket.wrapup.live/" className="dashboard-nav-item">
                        <img src="/images/sidebar/book.svg" alt="" /> Tickets
                    </a>
                )}
            </div>
            <div className="flex before:border">
                {AccessKeys.includes("expense") && (
                    <a href="https://expense.wrapup.live/" className="dashboard-nav-item">
                        <img src="/images/sidebar/dashboard.svg" alt="" /> Expense
                    </a>
                )}
            </div>
            <div className="flex before:border">
                {AccessKeys.includes("lms") && (
                    <a href="https://lms.wrapup.live/" className="dashboard-nav-item">
                        <img src="/images/sidebar/dashboard.svg" alt="" /> Lms
                    </a>
                )}
            </div>
            <div className="flex before:border">
                {AccessKeys.includes("salaryemployee") && (
                    <a href="https://salary.wrapup.live/" className="dashboard-nav-item">
                        <img src="/images/sidebar/dashboard.svg" alt="" /> Salary
                    </a>
                )}
            </div>
            <div className="flex before:border">
                {AccessKeys.includes("timeoff") && (
                    <a href="https://timeoff.wrapup.live/" className="dashboard-nav-item">
                        <img src="/images/sidebar/dashboard.svg" alt="" /> Leave
                    </a>
                )}
            </div>
            <div className="flex before:border">
                {AccessKeys.includes("salaryemployee") && (
                    <a href="https://salary.wrapup.live/document" className="dashboard-nav-item">
                        <img src="/images/sidebar/settings.svg" alt="" /> Profile Update
                    </a>
                )}
            </div>

        </>
    );
}

export { UserNavItems };
