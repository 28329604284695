import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Creategroups, GroupList, SideBar, Groupdetails } from "../../components/index";
import {
    user_signin,
} from "../../store/account/accountActions";
import { wrapup_employee_login, wrapup_manager_login, wrapup_admin_login } from "../../store/account/api"
import { Admin, Manager, User } from '../../config';
import { get_user_profile } from "../../store/account/api";
import { WebSocketComponent } from "../../components/index";



import $ from "jquery";


const Group = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const state_account = useSelector(state => state.account);
    const state_access = useSelector(state => state.access);
    const state_group = useSelector(state => state.group);
    const Getdata = localStorage.getItem("Access")
    const Token = localStorage.getItem("Token");
    const Accessdata = JSON.parse(localStorage.getItem("Accessdata"))
    const UserEmail = localStorage.getItem("UserEmail")

    const [addgroup, setaddgroup] = useState(false)
    async function closeNotification() {
        setShowNotification(false);
    };
    useEffect(() => {
        
        // if (Token != null) {
        //     dispatch(get_user_profile(Token, (res => {

        //     })))
        //     if (Getdata.includes(`${Admin}`)) {
        //     } else {
        //         navigate('/projects')
        //     }

        // }
        if (((Accessdata.find(item => item.access === "groups") && Accessdata.find(item => item.access === "groups")?.type != "noaccess")) || UserEmail === "admin@wrapup.com") {
            console.log(Accessdata)
            let data = Accessdata.find(item => item.access === "groups")?.type != "noaccess"
            console.log(data)
        } else {
            let data = Accessdata.find(item => item.access === "groups")?.type != "noaccess"
            console.log(data)
            navigate('/projects')
        }


    }, []);



    return (
        <>

            <div className="dashboard">
                <div className='dashboard-app' id="main_section_toggle">
                    <div className='dashboard-content'>
                        <div className=''>
                            <div className="row">
                                <SideBar />
                                <WebSocketComponent/>
                                <GroupList />

                                <div className="col-md-1" style={{ "width": "20px" }}>
                                    <div className="vl1">
                                    </div>
                                </div>
                                {state_group.addgroup ? (
                                    <Creategroups />
                                ) : (
                                    <></>
                                )}
                                {state_group.groupdetails ? (
                                    <Groupdetails />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                        <h5>{showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}


        </>
    );
}
export {
    Group
}
