
import axios from "axios"
import { wrapupApi } from '../../config';
import {
  get_access_list_success,
  add_access_success,
  get_user_sidebar_access_success,
  get_org_admin_success

} from "./accessActions";

export const get_access_list_pagination =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/access/get/paginate/accesss`, payload, config)
      .then(res => {

        const value = res.data

        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const create_access =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    

    axios.post(`${wrapupApi}/api/access/add-access`, payload, config)
      .then(res => {

        const value = res.data
        dispatch(add_access_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
        callback({ success: false, data: err });
      })
  };

export const update_access =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    let resp = axios.post(`${wrapupApi}/api/access/update-access`, payload, config)
      .then(res => {

        // const value = res.data
        // dispatch(update_access_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
        // callback({ success: false });
      })
    return resp;
  };


export const get_access_details =
  (callback) => async (dispatch) => {
    
    axios.get(`${wrapupApi}/api/access/get-access`)
      .then(res => {
        dispatch(get_access_list_success(res.data))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }
export const get_user_access_details =
  (payload,Token, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/access/get-user-access`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

  export const get_user_side_bar_access_details =
  (payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${payload.token}` }
    };
    axios.post(`${wrapupApi}/api/access/get-user-project-key`, payload, config)
      .then(res => {
        const filteredData = res.data.filter(item => item.projectdetails[0].length === 0);
        // Extracting 'access' property from each item in the filtered data
        const accessKeys = filteredData.map(item => item.access.access);
        dispatch(get_user_sidebar_access_success(accessKeys))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

  export const get_user_secondary_access =
  (paylaod, token, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${token}` }
    };
    axios.post(`${wrapupApi}/api/access/get-user-secondary-access`, paylaod, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  }

export const get_search_access_list_pagination =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/access/get/paginate/searchaccesslist`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };


export const get_org_admin =
  (Payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Payload.token}` }
    };
    axios.post(`${wrapupApi}/api/access/getorgadmin`, Payload, config )
      .then(res => {
        const value = res.data
        dispatch(get_org_admin_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };