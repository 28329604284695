import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { get_users_notification_count, users_notification } from "../../store/account/api";

const CommonNavItems = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const Token = localStorage.getItem("Token")
    const state_project = useSelector(state => state.project);



    const offset = 0;
    const count = 100;
    useEffect(() => {
        if (Token != null) {
            const payload = {
                email: state_account.profile.email,
                pageno: offset,
                size: count,
            }
            dispatch(users_notification(Token, payload, (res) => {

            }))
            const data = {
                email: state_account.profile.email
            }
            dispatch(get_users_notification_count(Token, data, (res => {
            })))
        }
    }, [state_project]);


    async function logout() {

        const cleanCookie = (name) => {
            document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        };

        // Example usage:
        cleanCookie('authToken');

        localStorage.clear();
        navigate('/');
        window.location.reload()
        Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'You Sucessfully logged-out in Wrap-up',
            showConfirmButton: false,
            timer: 1500
        });
    }
    return (
        <>
            <div className="flex before:border">
                <a href="/notification" className="dashboard-nav-item">
                    <img src="/images/icons/notification.svg" alt="" /> Notification
                    <span className="badge badge-color">{state_account.notificationcount}</span>
                </a>
            </div>
            <div className="flex hover:before:border">
                <a href="#" onClick={() => logout()} className="dashboard-nav-item">
                    <img src="/images/sidebar/logout.svg" alt="" /> Logout
                </a>
            </div>
        </>
    );
}

export { CommonNavItems };
