export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS';
export const CREATE_PROJECT_LIST_SUCCESS = 'CREATE_PROJECT_LIST_SUCCESS';
export const ADD_PROJECT_LIST_SUCCESS = 'ADD_PROJECT_LIST_SUCCESS';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const ADD_SINGLE_PROJECT = 'ADD_SINGLE_PROJECT';
export const GET_SINGLE_PROJECT_SUCCESS = 'GET_SINGLE_PROJECT_SUCCESS';
export const VIEW_PROJECT_ID_SUCCESS = 'VIEW_PROJECT_ID_SUCCESS'; 
export const SEARCH_PROJECT_SUCCESS = 'SEARCH_PROJECT_SUCCESS';
export const SEARCH_PROJECT_LIST_SUCCESS = 'SEARCH_PROJECT_LIST_SUCCESS'; 
export const PROJECTCOSTSUCCESS = 'PROJECTCOSTSUCCESS'; 
export const PROJECTCURRENCYSUCCESS = 'PROJECTCURRENCYSUCCESS'; 

export const JIRAISSUESFORAPROJECTSUCCESS = 'JIRAISSUESFORAPROJECTSUCCESS' 
export const JIRAISSUESDETAILSSUCCESS = 'JIRAISSUESDETAILSSUCCESS' 
export const VIEW_ISSUE_ID_SUCCESS = 'VIEW_ISSUE_ID_SUCCESS'
export const SELECTAPROJECTFORACCESSSUCCESS = 'SELECTAPROJECTFORACCESSSUCCESS'
export const PROJECTWISEGETUSERSSUCCESS = 'PROJECTWISEGETUSERSSUCCESS'
export const GETACCESSABLEPROJECTCOUNTSSUCCESS = 'GETACCESSABLEPROJECTCOUNTSSUCCESS'

export const PROJECTLISTVIEW = 'PROJECTLISTVIEW'
export const GET_EPIC_LIST_SUCCESS = 'GET_EPIC_LIST_SUCCESS'
export const GET_SELECTED_EPIC = 'GET_SELECTED_EPIC'
export const LOADING_STATUS_UPDATE = 'LOADING_STATUS_UPDATE' 
export const ISSUE_STATUS_TYPE_UPDATE = 'ISSUE_STATUS_TYPE_UPDATE' 
export const GET_ORGANIZATION_USER_SUCCESS = 'GET_ORGANIZATION_USER_SUCCESS'