import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {DynamicBarCharts, Createproject, ProjectList, SideBar, Projectdetails, Createtask, Updatetask, WebSocketComponent } from "../../components/index";
import {
    setloading,updateissuestatus,
    getepiclist, view_issue_id, get_project_details_success, get_project_list_success, getjiraprojectwiseissue,
    projectlistviewsuccess, view_project_id, selectproejctforaccess, getaccessableprojectcount,
    getjiraprojectwiseissuedetails, selectepic
} from "store/projects/projectActions";
import { get_org_admin } from "../../store/access/api"
import {
    user_signin,
} from "../../store/account/accountActions";
// import { wrapup_employee_login, wrapup_manager_login, wrapup_admin_login } from "../../store/account/api"
import { get_issue_list_for_jira_project, get_epic_list_for_jira_project } from "store/projects/api"

import { get_project_list_pagination, getSprintChartData } from "store/projects/api";
import Swal from 'sweetalert2'
import $ from "jquery";
import { get_user_profile } from "../../store/account/api";
import { Admin, Manager, User } from '../../config';



const Projects = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { projectKey, issueKey } = useParams(); // Get the project and issue from URL
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const state_account = useSelector(state => state.account);
    const state_project = useSelector(state => state.project);
    const state_task = useSelector(state => state.task);
    const state_access = useSelector(state => state.access);
    const Token = localStorage.getItem("Token")
    const [pageno, setpageno] = useState(0)
    const [count, setcount] = useState(20)
    const projects = state_project.projectlist
    const [project, setProject] = useState([])
    const Getdata = localStorage.getItem("Access")
    let Accessdata = []
    if (localStorage.getItem("Accessdata") != "undefined") {
        Accessdata = JSON.parse(localStorage.getItem("Accessdata"))
    }
    let GroupadminAccessdata = []
    if (localStorage.getItem("GroupadminAccessdata") != "undefined") {
        GroupadminAccessdata = JSON.parse(localStorage.getItem("GroupadminAccessdata"))
    }
    const UserEmail = localStorage.getItem("UserEmail")
    const [searchQuery, setSearchQuery] = useState('')
    // const state_project = useSelector(state => state.projectlist);

    const [projectlist, setprojectlist] = useState(false)



    useEffect(() => {
        // updateview()
        getprojectlist();
    }, [projectKey]);
    useEffect(() => {
        const payload = {
            email: UserEmail,
            token: Token
        }
        dispatch(get_org_admin(payload, (res)=>{
            if(res.success===true){
            const payload = {
                email: UserEmail,
                isAdmin: res.data.isOrgAdmin,
                userAccessibleProjects: Accessdata.map(item => item.access)
            }
    
            dispatch(getSprintChartData(Token, payload, (res) => {
                if (res.success == true) {
                    setSprintData(res.data)
                }
            }))}
        }))
    }, []);

    useEffect(() => {
        const fetchProjectData = async () => {
            // Define your async function here
            const getProjectData = () => {
                // Filter the project list based on the search query and user access
                const accessibleProjects = state_project.projectlist
                    .filter(data =>
                        // First filter by name or key matching the search query
                        data.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        data.key.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .filter(data => {
                        // Then check if the user has access to the project
                        const isAccessAllowed = Accessdata.find(item => item.access === data?.key && item.type !== "noaccess") !== undefined;
                        return isAccessAllowed || UserEmail === "admin@wrapup.com";
                    })
                    .find(data => data.key === projectKey); // Find the specific project by key

                // Return the found project or null if not found
                return accessibleProjects || null;
            };

            if (projectKey && state_project.projectlist.length > 0) {
                const projectData = getProjectData(); // Call the synchronous function to get the project data
                if (projectData) {
                    await GetIssueList(projectData); // Assuming GetIssueList is also async, await it
                }
            }
        };

        fetchProjectData(); // Call the async function
    }, [projectKey, state_project.projectlist]);
    // Handler function when a bar is clicked
    const handleBarClick = (status, projectKey) => {
        dispatch(updateissuestatus(status))
        navigate(`/projects/${projectKey}`);
    };
    const [sprintData, setSprintData] = useState([])
    // async function getprojectlistpagination(data) {
    //     const payload = {
    //         pageno: data,
    //         count: count,
    //         user: state_account.profile
    //     }
    //     dispatch(get_project_list_pagination(Token, payload, (res) => {
    //         if (res.success == true) {
    //             let value = res.data
    //             value.forEach(i => {
    //                 projects.push(i)
    //             })
    //             get_project_list_success(projects)
    //             setProject(projects)
    //         }
    //     }))
    // }
    async function closeNotification() {
        setShowNotification(false);
    };

    async function addStatusboxToObject(obj) {
        // Add the "statusbox" property to the object
        obj.statusbox = false; // Replace "your_value_here" with the desired value

        // If the object has subtasks, add the "statusbox" property to each subtask
        if (obj.subTasks && Array.isArray(obj.subTasks)) {
            obj.subTasks.forEach((subtask) => {
                addStatusboxToObject(subtask);
            });
        }
    }

    async function GetIssueList(data, index, status=null) {
        if(index>=0){
        navigate(`/projects/${data.key}`);}
        dispatch(setloading(true))
        if(issueKey){dispatch(updateissuestatus(""))}
        let allowvalue = (UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === data?.key && item.type !== "noaccess")) ? true : false
        dispatch(selectproejctforaccess(data))
        dispatch(get_project_details_success(false))

        const payload = {
            "projectKey": data.key,
            "projectname": data.name,
            "userEmail": UserEmail,
            "allow": allowvalue,
            "firstname": state_account.profile.firstname,
            "lastname": state_account.profile.lastname,
            "status": issueKey?"": status? status: state_project.issuestatustype,
            "searchvalue": issueKey?issueKey:"",
            "epic": ""
        }
        dispatch(get_epic_list_for_jira_project(Token, payload))
        dispatch(get_issue_list_for_jira_project(Token, payload, async (res) => {
            if(res.success===true){
            if (res.data.length != 0) {
                dispatch(projectlistviewsuccess(true))
                if(issueKey){selectIssue(res.data[0])}
                dispatch(setloading(false))
            }
            else {
                dispatch(projectlistviewsuccess(true))
                dispatch(setloading(false))
                Swal.fire('No task found', '', 'info')
            }}

        }))
    }

    // Function to add comments field to an object
    async function addCommentsField(obj) {
        if (!obj.comments) {
            obj.comments = [];
        }
        if (!obj.files) {
            obj.files = []
        }
    }
    async function selectIssue(data) {
        await addCommentsField(data)
        // Add comments field to each subtask object
        data.subTasks.forEach(async subtask => await addCommentsField(subtask));
        dispatch(get_project_details_success(true))
        dispatch(view_issue_id(data.key))
        const newData = JSON.parse(JSON.stringify(data));
        await addStatusboxToObject(newData);
        // console.log(newData)
        dispatch(getjiraprojectwiseissuedetails(newData))
    }


    async function getprojectlist() {
        const payload = {
            pageno: 0,
            count: 50,
            user: state_account.profile,
            accesstype: "admin"
        }

        dispatch(get_project_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                dispatch(get_project_list_success(value))
                const accessibleProjects = (value || []).filter(data =>
                    (Accessdata.find(item => item.access === data?.key && item.type !== "noaccess") || UserEmail === "admin@wrapup.com")
                );
                dispatch(getaccessableprojectcount(accessibleProjects.length))
            }
        }))
    }

    function updateview() {
        dispatch(projectlistviewsuccess(false))
        dispatch(selectepic(''))
        dispatch(view_issue_id(''))
        dispatch(getepiclist([]))
        dispatch(getjiraprojectwiseissuedetails(''))
        dispatch(getjiraprojectwiseissue([]))
        dispatch(getepiclist([]))
        navigate('/projects')
    }

    return (
        <>

            <div className="dashboard">
                <div className='dashboard-app' id="main_section_toggle">
                    {/* <p className='dashboard-toolbar'><a href="#!" className="menu-toggle" onClick={sideartoggle}><i style={{ "fontSize": "24px" }}
                        className="fa">&#xf0c9;</i></a></p> */}
                    <div className='dashboard-content'>
                        <div className=''>
                            <SideBar />
                            <WebSocketComponent />
                            {sprintData.length>0 && state_project.projectlistview == false && <div>
                                <h5>Current running sprint: {sprintData.length}</h5>
                                <DynamicBarCharts sprintData={sprintData} onBarClick={handleBarClick} />
                            </div>}

                            {state_project.projectlistview == false &&
                                <>
                                    {/* <h5>Total Project : <span>{state_project.accessibleProjectCount}</span></h5> */}
                                    <div className="col-md-12 project-row" style={{ "marginBottom": "20px" }}>
                                        <h5>Total Project : <span>{state_project.accessibleProjectCount}</span></h5>
                                        <div>
                                            <input type="text" placeholder="Search project" className="search-form-control" value={searchQuery} input="true"
                                                onChange={(e) => setSearchQuery(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ "padding": "0" }}>
                                        {state_project.accessibleProjectCount != 0 ? (
                                            <table width={"100%"} className="project_list_table">
                                                <tbody>
                                                    <tr key={`project-list-header`}>
                                                        <th><div className="flex">Project name <img src="/images/icons/sort.svg" width={"18px"} /></div></th>
                                                        <th>Project key</th>
                                                        <th></th>
                                                    </tr>
                                                    {state_project.projectlist
                                                        .filter(data =>
                                                            data.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                                            data.key.toLowerCase().includes(searchQuery.toLowerCase())
                                                        )
                                                        .map((data, index) => {
                                                            const isAccessAllowed = Accessdata.find(item => item.access === data?.key && item.type !== "noaccess") !== undefined;
                                                            // Create a unique key based on the index
                                                            const uniqueKey = `project-list-${index}`;

                                                            return (
                                                                isAccessAllowed || UserEmail === "admin@wrapup.com" ? (
                                                                    <tr key={uniqueKey} onClick={() => GetIssueList(data, index)}>
                                                                        <td key={`project-name${index}`}>
                                                                            <a className="cursor">
                                                                                {data?.key}-{data?.name}
                                                                            </a>
                                                                        </td>
                                                                        <td key={`project-key${index}`}>
                                                                            <a href="#" className="project_key">
                                                                                {data?.key}-{data?.name} 🔑
                                                                            </a>
                                                                        </td>
                                                                        <td key={`project-view${index}`}>
                                                                            <a href="#" className="project_key_view">View</a>
                                                                            {/* <img src="/images/icons/delete.svg" width={"20px"} className="cursor" /> */}
                                                                        </td>
                                                                    </tr>
                                                                ) : null
                                                            );
                                                        })}
                                                </tbody>
                                            </table>)
                                            : (
                                                <div className="project-manager-section-box" style={{ "marginTop": "0px", "marginBottom": "20px" }} key={`project-list-default`}>
                                                    <div className="text-center">
                                                        <img src="/images/icons/no_task.svg" />
                                                        <h5 className="my-0">You don't have any project to work on </h5>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </>}







                            {state_project.projectlistview == true &&
                                <><div href="#" className="back-link" onClick={()=>updateview()}>
                                    <span className="back-icon">&#8592;</span> Back to project list
                                </div>

                                    <div className="row">
                                        <ProjectList />
                                        <div className="col-md-1" style={{ "width": "20px" }}>
                                            <div className="vl1">
                                            </div>
                                            {/* <div className="vl1">
                                    </div>
                                    <div className="vl1">
                                    </div>
                                    <div className="vl1">
                                    </div> */}
                                        </div>
                                        {state_project.addproject ? (
                                            <Createproject />
                                        ) : (
                                            <></>
                                        )}

                                        {projectKey && issueKey && state_project.projectdetails ? (
                                            <Projectdetails />
                                        ) : (
                                            <></>
                                        )}
                                        {state_task.updatetask ? (
                                            <Updatetask />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>

            </div>

            {showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                        <h5>{showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}


            {state_project.loadingstatus &&<div className="loader-wrap">
                <div className="loader"></div>
            </div>}
        </>
    );
}
export {
    Projects
}
