export const GET_ACCESS_LIST_SUCCESS = 'GET_ACCESS_LIST_SUCCESS';
export const CREATE_ACCESS_LIST_SUCCESS = 'CREATE_ACCESS_LIST_SUCCESS';
export const ADD_ACCESS_LIST_SUCCESS = 'ADD_ACCESS_LIST_SUCCESS';
export const GET_ACCESS_DETAILS_SUCCESS = 'GET_ACCESS_DETAILS_SUCCESS';
export const ADD_SINGLE_ACCESS = 'ADD_SINGLE_ACCESS';
export const GET_SINGLE_ACCESS_SUCCESS = 'GET_SINGLE_ACCESS_SUCCESS';
export const VIEW_ACCESS_ID_SUCCESS = 'VIEW_ACCESS_ID_SUCCESS'; 
export const SEARCH_ACCESS_SUCCESS = 'SEARCH_ACCESS_SUCCESS';
export const SEARCH_ACCESS_LIST_SUCCESS = 'SEARCH_ACCESS_LIST_SUCCESS'; 
export const IS_ACCESS_CREATE_SUCCESS = 'IS_ACCESS_CREATE_SUCCESS'; 
export const USER_ACCESS_GET_SUCCESS = 'USER_ACCESS_GET_SUCCESS'; 
export const USER_SIDEBAR_ACCESS_GET_SUCCESS = 'USER_SIDEBAR_ACCESS_GET_SUCCESS'; 
export const GET_ORG_ADMIN_SUCCESS = 'GET_ORG_ADMIN_SUCCESS';



