import React from 'react'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { get_employees, get_users, get_user_profile } from 'store/account/api';
import { SideBar, WebSocketComponent } from 'components';
import { Admin, Manager } from '../../config';
import {get_all_user} from "../../store/account/accountActions"


const Users = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Token = localStorage.getItem("Token");
    const Getdata = localStorage.getItem("Access")
    const state_account = useSelector(state => state.account);
    const state_access = useSelector(state => state.access);
    const Accessdata = JSON.parse(localStorage.getItem("Accessdata"))
    const UserEmail = localStorage.getItem("UserEmail")

    useEffect(() => {        
        dispatch(get_users(Token))

        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {

                dispatch(get_users(Token))

            })))
        }
        if ((Accessdata.find(item => item?.access === "users")?.type != "noaccess") || UserEmail === "admin@wrapup.com") {

        } else {
            navigate('/projects')
        }


    }, [])

    console.log(state_account.employees)





    return (
        <div>
            <div className='dashboard'>
                <SideBar />
                <WebSocketComponent/>
                <div className='dashboard-app' id="main_section_toggle">
                    {/* <p className='dashboard-toolbar'><a href="#!" className="menu-toggle"><i style={{ "fontSize": "24px" }}
                        className="fa">&#xf0c9;</i></a></p> */}
                    <div className='dashboard-content'>
                        <div>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="manager-wrap">
                                        <div className="manager-title">
                                            <p>Users </p>
                                            <h6>Number of Users: {state_account.users.length}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(state_account.users || []).map((data, index) => (
                                <div className="padding-section">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="project-manager-section-box">
                                                <div className="project-box">
                                                    <div className="project-row">
                                                        <div className="project-manager-row">
                                                            <div className="profile-img-container">
                                                                {/* {/ <div className="profile-img" style={{ "padding": "2px" }}> /} */}
                                                                <img src={data.profileimage != "" ? data.profileimage : "/images/icons/Default_profile.svg"} alt="" className="avatar-img" />
                                                                {/* {/ </div > /} */}
                                                            </div>
                                                            <div className="manager-name-section">
                                                                <p>{data.firstname} {data.lastname}</p>
                                                            </div>
                                                        </div>
                                                        <div className="deperment-devoloper">
                                                            {/* {/ <h6>Manager</h6 > /} */}
                                                        </div>
                                                    </div>
                                                    <div className="project-heading-wrap-of">
                                                        <div className="heading-title">
                                                            <h6>Tasks</h6>
                                                        </div>

                                                        <div className="number-of-project">
                                                            {data.assignedIssues?.length ? (
                                                                <div className="row">
                                                                    <div className="new-arrivals-containar">
                                                                        {(data.assignedIssues || []).map((projects, index) => (
                                                                            <div key={index}>
                                                                                <div className="new-look-img-section" >
                                                                                    <div className="project-manager-box-completed">
                                                                                        <div className="project-manager-row">
                                                                                            <div className="manager-project" >
                                                                                                <p>{projects.key}</p>
                                                                                                <div className="due-date">
                                                                                                     <h6>Due date: {new Intl.DateTimeFormat('en-GB', {
                                                                                                        month: 'long',
                                                                                                        day: '2-digit',
                                                                                                        year: 'numeric',
                                                                                                    }).format(new Date(projects.dueDate))}</h6>
                                                                                                </div>
                                                                                                <div className="description-section">
                                                                                                    {/* <span>Description:</span> */}
                                                                                                    <h5>{projects.summary}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                </div>)
                                                                :
                                                                (<div className="text-center">
                                                                    <img src="/images/icons/no_task.svg" />
                                                                    <h5 className="my-0">No tasks assigned</h5>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            ))}

                            {/* 
                            {(state_account.users || []).map((data, index) => (
                                <div className="padding-section">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="project-manager-section-box">
                                                <div className="project-box">
                                                    <div className="project-row">
                                                        <div className="project-manager-row">
                                                            <div className="project-manager-image">
                                                                <img src="images/task manager/demo-img.png" alt="" />
                                                            </div>
                                                            <div className="manager-name-section">
                                                                <p>{data.firstname} {data.lastname}</p>
                                                                <p>{data.email}</p>
                                                            </div>
                                                        </div>
                                                        <div className="deperment-devoloper">
                                                            <h6>{data.grouptype}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="project-heading-wrap-of">
                                                        <div className="heading-title">
                                                            <h6>Tasks</h6>
                                                        </div>

                                                        <div className="number-of-project">
                                                            <div className="row">
                                                                <div className="new-arrivals-containar">
                                                                    {(data.usertasks || []).map((tasks, index) => (
                                                                        <div className="">
                                                                            <div className="new-look-img-section">
                                                                                <div className="project-manager-box-completed">
                                                                                    <div className="project-manager-row">
                                                                                        <div className="manager-project">
                                                                                            <p>{tasks.taskname}</p>
                                                                                            <div className="due-date">
                                                                                                <h6>{new Intl.DateTimeFormat('en-GB', {
                                                                                                    month: 'long',
                                                                                                    day: '2-digit',
                                                                                                    year: 'numeric',
                                                                                                }).format(new Date(tasks.updatedAt))}</h6>
                                                                                            </div>
                                                                                            <div className="description-section">
                                                                                                <h5>Description: </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="status-section">
                                                                                            <p>{tasks.taskdetails}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="manager-details-wrap">
                                                                                        <p> {tasks.taskdescription}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>))} */}


                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
}

export {
    Users
}